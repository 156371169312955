import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormGroupDirective,
  NgForm,
  FormControl,
  Validators
} from "@angular/forms";
import { ErrorStateMatcher } from "@angular/material/core";
import { Contact } from "./contact";
import { EmailService } from "../services/email.service";
import { ToastrService } from "ngx-toastr";

/** Error when invalid control is dirty, touched, or submitted. */
export class NgLpErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const isSubmitted = form && form.submitted;
    return !!(
      control &&
      control.invalid &&
      (control.dirty || control.touched || isSubmitted)
    );
  }
}

@Component({
  selector: "app-contact",
  templateUrl: "./contact.component.html",
  styleUrls: ["./contact.component.css"]
})
export class ContactComponent implements OnInit {
  disabled = true;
  nameFormControl = new FormControl("", [Validators.required]);

  textControlName = new FormControl("", [Validators.required]);

  emailFormControl = new FormControl("", [
    Validators.required,
    Validators.email
  ]);

  matcher = new NgLpErrorStateMatcher();

  contact = new Contact();

  constructor(
    private emailService: EmailService,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    this.disabled = true;
  }

  resolved(captchaResponse: string) {
    if (captchaResponse) {
      this.disabled = false;
    }
  }

  onSubmit() {
    try {
      if (
        this.nameFormControl.valid &&
        this.emailFormControl.valid &&
        this.textControlName.valid
      ) {
        const resp = this.emailService
          .sendMail(
            this.emailFormControl.value,
            this.nameFormControl.value,
            this.textControlName.value
          )
          .subscribe(response => {
            console.log(response);
          });
        this.toastr.success("Ok!", "Mail enviado!");
      } else {
        this.toastr.error("Oops!", "Hay campos inválidos en el formulario..");
      }
    } catch {
      this.toastr.error("Oops!", "Ocurrió un error, vuelva a intentarlo..");
    }
  }
}
