import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-heading',
  templateUrl: './heading.component.html',
  styleUrls: ['./heading.component.css']
})
export class HeadingComponent implements OnInit {

  isHeading = true;
  isSubheading = true;
  isHeadingBtn = true;
  trackingLink = 'http://mercury.sisorgcloud.com/tracking/RPCOGEjmp10UZD4McDHset9vx9fqukh9rS0HlWWyo9vGXcWhWSQMCYsyx%2BNAAjh0KiDCbsq02jt2yJP***WXkPyjSRDR4yQoQAGpELHQa8Tjb30n2Fdq7tnhQCo3wVGYkOLwrjAa65llp4btiaZ3inRAf8HXCm67sYP9D%2BWOjZUUnjqWLAXB8JZzFLlVGxydWz'
  constructor() { }

  ngOnInit() {
  }

}
