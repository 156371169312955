import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule, LOCALE_ID, APP_INITIALIZER } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FlexLayoutModule } from "@angular/flex-layout";
import { DirectivesModule } from "./directives/directives.module";
import { Material2Module } from "./material2.module";
import { HttpClientModule } from "@angular/common/http";
import { AppComponent } from "./app.component";
import { PortfolioComponent } from "./portfolio/portfolio.component";
import { AboutComponent } from "./about/about.component";
import { HeadingComponent } from "./heading/heading.component";
import { PricingComponent } from "./pricing/pricing.component";
import { BlogComponent } from "./blog/blog.component";
import { ContactComponent } from "./contact/contact.component";
import { ContactDialogComponent } from "./contact-dialog/contact-dialog.component";
import { TranslateService } from "./services/translate.service";
import { TranslatePipe } from "src/app/pipes/translate.pipe";
import { TeamComponent } from "./team/team.component";
import { FeaturesComponent } from "./features/features.component";
import { ToastrModule } from "ngx-toastr";
import { RecaptchaModule, RecaptchaFormsModule } from "ng-recaptcha";
export function setupTranslateFactory(service: TranslateService): Function {
  return () => service.use("es");
}

@NgModule({
  declarations: [
    AppComponent,
    PortfolioComponent,
    AboutComponent,
    HeadingComponent,
    PricingComponent,
    BlogComponent,
    ContactComponent,
    ContactDialogComponent,
    TranslatePipe,
    TeamComponent,
    FeaturesComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    Material2Module,
    FlexLayoutModule,
    DirectivesModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    RecaptchaModule, //this is the recaptcha main module
    RecaptchaFormsModule, //this is the module for form incase form validation
    ToastrModule.forRoot()
  ],
  providers: [
    { provide: LOCALE_ID, useValue: "es-AR" },
    TranslateService,
    {
      provide: APP_INITIALIZER,
      useFactory: setupTranslateFactory,
      deps: [TranslateService],
      multi: true
    }
  ],
  entryComponents: [ContactDialogComponent],
  bootstrap: [AppComponent]
})
export class AppModule {}
