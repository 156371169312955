import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: "root"
})
export class EmailService {
  constructor(private httpClient: HttpClient) {}

  public sendMail(dest, name, text) {
    if (dest !== "" && name !== "" && text !== "") {
      return this.httpClient.get(
        `https://us-central1-transportemercure.cloudfunctions.net/sendMail?dest=${dest}&name=${name}&text=${text}`,
        { responseType: "text" }
      );
    } else {
      return null;
    }
  }
}
